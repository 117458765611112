import React from 'react';
import {
  Router as DefaultRouter,
  Route,
  Switch,
  StaticRouter,
} from 'react-router-dom';
import dynamic from 'umi/dynamic';
import renderRoutes from 'umi/lib/renderRoutes';
import history from '@@/history';
import RendererWrapper0 from 'E:/workerspace/fDeerGod/src/pages/.umi-production/LocaleWrapper.jsx';
import _dvaDynamic from 'dva/dynamic';

const Router = require('dva/router').routerRedux.ConnectedRouter;

const routes = [
  {
    path: '/user',
    name: 'user',
    component: __IS_BROWSER
      ? _dvaDynamic({
          component: () =>
            import(/* webpackChunkName: "layouts__UserLayout" */ '../../layouts/UserLayout'),
          LoadingComponent: require('E:/workerspace/fDeerGod/src/components/PageLoading/index')
            .default,
        })
      : require('../../layouts/UserLayout').default,
    routes: [
      {
        path: '/user',
        redirect: '/user/login',
        exact: true,
      },
      {
        path: '/user/login',
        name: 'login',
        component: __IS_BROWSER
          ? _dvaDynamic({
              app: require('@tmp/dva').getApp(),
              models: () => [
                import(/* webpackChunkName: 'p__User__models__register.js' */ 'E:/workerspace/fDeerGod/src/pages/User/models/register.js').then(
                  m => {
                    return { namespace: 'register', ...m.default };
                  },
                ),
              ],
              component: () =>
                import(/* webpackChunkName: "p__User__NewLogin" */ '../User/NewLogin'),
              LoadingComponent: require('E:/workerspace/fDeerGod/src/components/PageLoading/index')
                .default,
            })
          : require('../User/NewLogin').default,
        exact: true,
      },
      {
        path: '/user/register',
        name: 'register',
        component: __IS_BROWSER
          ? _dvaDynamic({
              app: require('@tmp/dva').getApp(),
              models: () => [
                import(/* webpackChunkName: 'p__User__models__register.js' */ 'E:/workerspace/fDeerGod/src/pages/User/models/register.js').then(
                  m => {
                    return { namespace: 'register', ...m.default };
                  },
                ),
              ],
              component: () =>
                import(/* webpackChunkName: "p__User__Register" */ '../User/Register'),
              LoadingComponent: require('E:/workerspace/fDeerGod/src/components/PageLoading/index')
                .default,
            })
          : require('../User/Register').default,
        exact: true,
      },
      {
        path: '/user/modpwd',
        name: 'modpwd',
        component: __IS_BROWSER
          ? _dvaDynamic({
              app: require('@tmp/dva').getApp(),
              models: () => [
                import(/* webpackChunkName: 'p__User__models__register.js' */ 'E:/workerspace/fDeerGod/src/pages/User/models/register.js').then(
                  m => {
                    return { namespace: 'register', ...m.default };
                  },
                ),
              ],
              component: () =>
                import(/* webpackChunkName: "p__User__ModPwd" */ '../User/ModPwd'),
              LoadingComponent: require('E:/workerspace/fDeerGod/src/components/PageLoading/index')
                .default,
            })
          : require('../User/ModPwd').default,
        exact: true,
      },
      {
        path: '/user/register-result',
        component: __IS_BROWSER
          ? _dvaDynamic({
              app: require('@tmp/dva').getApp(),
              models: () => [
                import(/* webpackChunkName: 'p__User__models__register.js' */ 'E:/workerspace/fDeerGod/src/pages/User/models/register.js').then(
                  m => {
                    return { namespace: 'register', ...m.default };
                  },
                ),
              ],
              component: () =>
                import(/* webpackChunkName: "p__User__RegisterResult" */ '../User/RegisterResult'),
              LoadingComponent: require('E:/workerspace/fDeerGod/src/components/PageLoading/index')
                .default,
            })
          : require('../User/RegisterResult').default,
        exact: true,
      },
    ],
  },
  {
    path: '/',
    component: __IS_BROWSER
      ? _dvaDynamic({
          component: () =>
            import(/* webpackChunkName: "layouts__BasicLayout" */ '../../layouts/BasicLayout'),
          LoadingComponent: require('E:/workerspace/fDeerGod/src/components/PageLoading/index')
            .default,
        })
      : require('../../layouts/BasicLayout').default,
    Routes: [require('../Authorized').default],
    routes: [
      {
        path: '/',
        redirect: '/homepage',
        exact: true,
      },
      {
        path: '/homepage',
        icon: 'icon-Home',
        name: 'homepage',
        hideChildrenInMenu: true,
        routes: [
          {
            path: '/homepage',
            redirect: '/homepage/view',
            exact: true,
          },
          {
            path: '/homepage/view',
            name: 'homepageview',
            component: __IS_BROWSER
              ? _dvaDynamic({
                  app: require('@tmp/dva').getApp(),
                  models: () => [
                    import(/* webpackChunkName: 'p__Site__HomePage__model.js' */ 'E:/workerspace/fDeerGod/src/pages/Site/HomePage/model.js').then(
                      m => {
                        return { namespace: 'model', ...m.default };
                      },
                    ),
                    import(/* webpackChunkName: 'p__Site__models__widthdrawmodel.js' */ 'E:/workerspace/fDeerGod/src/pages/Site/models/widthdrawmodel.js').then(
                      m => {
                        return { namespace: 'widthdrawmodel', ...m.default };
                      },
                    ),
                  ],
                  component: () =>
                    import(/* webpackChunkName: "layouts__BasicLayout" */ '../Site/HomePage/HomePage'),
                  LoadingComponent: require('E:/workerspace/fDeerGod/src/components/PageLoading/index')
                    .default,
                })
              : require('../Site/HomePage/HomePage').default,
            exact: true,
          },
          {
            path: '/homepage/view/productview/:id',
            name: 'view',
            component: __IS_BROWSER
              ? _dvaDynamic({
                  app: require('@tmp/dva').getApp(),
                  models: () => [
                    import(/* webpackChunkName: 'p__Site__HomePage__model.js' */ 'E:/workerspace/fDeerGod/src/pages/Site/HomePage/model.js').then(
                      m => {
                        return { namespace: 'model', ...m.default };
                      },
                    ),
                    import(/* webpackChunkName: 'p__Site__models__widthdrawmodel.js' */ 'E:/workerspace/fDeerGod/src/pages/Site/models/widthdrawmodel.js').then(
                      m => {
                        return { namespace: 'widthdrawmodel', ...m.default };
                      },
                    ),
                  ],
                  component: () =>
                    import(/* webpackChunkName: "layouts__BasicLayout" */ '../Site/HomePage/ProductView'),
                  LoadingComponent: require('E:/workerspace/fDeerGod/src/components/PageLoading/index')
                    .default,
                })
              : require('../Site/HomePage/ProductView').default,
            exact: true,
          },
        ],
      },
      {
        name: 'account',
        icon: 'icon-zhanghu1',
        path: '/account',
        routes: [
          {
            path: '/account/account',
            name: 'account',
            icon: 'icon-zhanghu5',
            hideChildrenInMenu: true,
            routes: [
              {
                path: '/account/account',
                redirect: '/account/account/list',
                exact: true,
              },
              {
                path: '/account/account/list',
                name: 'list',
                component: __IS_BROWSER
                  ? _dvaDynamic({
                      app: require('@tmp/dva').getApp(),
                      models: () => [
                        import(/* webpackChunkName: 'p__Boss__Account__models__account.js' */ 'E:/workerspace/fDeerGod/src/pages/Boss/Account/models/account.js').then(
                          m => {
                            return { namespace: 'account', ...m.default };
                          },
                        ),
                      ],
                      component: () =>
                        import(/* webpackChunkName: "layouts__BasicLayout" */ '../Boss/Account/AccountList'),
                      LoadingComponent: require('E:/workerspace/fDeerGod/src/components/PageLoading/index')
                        .default,
                    })
                  : require('../Boss/Account/AccountList').default,
                exact: true,
              },
              {
                path: '/account/account/view/:id',
                name: 'view',
                component: __IS_BROWSER
                  ? _dvaDynamic({
                      app: require('@tmp/dva').getApp(),
                      models: () => [
                        import(/* webpackChunkName: 'p__Boss__Account__models__account.js' */ 'E:/workerspace/fDeerGod/src/pages/Boss/Account/models/account.js').then(
                          m => {
                            return { namespace: 'account', ...m.default };
                          },
                        ),
                      ],
                      component: () =>
                        import(/* webpackChunkName: "layouts__BasicLayout" */ '../Boss/Account/AccountView'),
                      LoadingComponent: require('E:/workerspace/fDeerGod/src/components/PageLoading/index')
                        .default,
                    })
                  : require('../Boss/Account/AccountView').default,
                exact: true,
              },
            ],
          },
          {
            path: '/account/businessorder',
            name: 'businessorder',
            hideChildrenInMenu: true,
            icon: 'icon-qiyexinxi2',
            routes: [
              {
                path: '/account/businessorder',
                redirect: '/account/businessorder/list',
                exact: true,
              },
              {
                path: '/account/businessorder/list',
                name: 'list',
                component: __IS_BROWSER
                  ? _dvaDynamic({
                      app: require('@tmp/dva').getApp(),
                      models: () => [
                        import(/* webpackChunkName: 'p__Boss__Professional__models__accountModel.js' */ 'E:/workerspace/fDeerGod/src/pages/Boss/Professional/models/accountModel.js').then(
                          m => {
                            return { namespace: 'accountModel', ...m.default };
                          },
                        ),
                      ],
                      component: () =>
                        import(/* webpackChunkName: "layouts__BasicLayout" */ '../Boss/Professional/ProfessionalChild'),
                      LoadingComponent: require('E:/workerspace/fDeerGod/src/components/PageLoading/index')
                        .default,
                    })
                  : require('../Boss/Professional/ProfessionalChild').default,
                exact: true,
              },
            ],
          },
        ],
      },
      {
        name: 'notification',
        icon: 'icon-xiaoxiguanli',
        path: '/notification',
        routes: [
          {
            path: '/notification/mynotice',
            name: 'mynotice',
            icon: 'icon-xiaoxi',
            hideChildrenInMenu: true,
            routes: [
              {
                path: '/notification/mynotice',
                redirect: '/notification/mynotice/list',
                exact: true,
              },
              {
                path: '/notification/mynotice/list',
                name: 'list',
                component: __IS_BROWSER
                  ? _dvaDynamic({
                      component: () =>
                        import(/* webpackChunkName: "layouts__BasicLayout" */ '../Notification/NotificationList'),
                      LoadingComponent: require('E:/workerspace/fDeerGod/src/components/PageLoading/index')
                        .default,
                    })
                  : require('../Notification/NotificationList').default,
                exact: true,
              },
            ],
          },
        ],
      },
      {
        name: 'site',
        icon: 'icon-jichu',
        path: '/basic',
        routes: [
          {
            path: '/basic/org',
            name: 'org',
            component: __IS_BROWSER
              ? _dvaDynamic({
                  app: require('@tmp/dva').getApp(),
                  models: () => [
                    import(/* webpackChunkName: 'p__Site__Org__models__orgInfo.js' */ 'E:/workerspace/fDeerGod/src/pages/Site/Org/models/orgInfo.js').then(
                      m => {
                        return { namespace: 'orgInfo', ...m.default };
                      },
                    ),
                    import(/* webpackChunkName: 'p__Site__models__widthdrawmodel.js' */ 'E:/workerspace/fDeerGod/src/pages/Site/models/widthdrawmodel.js').then(
                      m => {
                        return { namespace: 'widthdrawmodel', ...m.default };
                      },
                    ),
                  ],
                  component: () =>
                    import(/* webpackChunkName: "layouts__BasicLayout" */ '../Site/Org/OrgInfo'),
                  LoadingComponent: require('E:/workerspace/fDeerGod/src/components/PageLoading/index')
                    .default,
                })
              : require('../Site/Org/OrgInfo').default,
            needCtrlChidrenBtn: true,
            hideChildrenInMenu: true,
            icon: 'icon-qiyexinxi1',
            routes: [
              {
                path: '/basic/org',
                redirect: '/basic/org/department',
                exact: true,
              },
              {
                path: '/basic/org/department',
                name: 'info',
                btns: [
                  {
                    key: 'edit',
                    label: '修改',
                  },
                  {
                    key: 'dealPwd',
                    label: '交易密码',
                  },
                ],
                component: __IS_BROWSER
                  ? _dvaDynamic({
                      app: require('@tmp/dva').getApp(),
                      models: () => [
                        import(/* webpackChunkName: 'p__Site__Org__models__orgInfo.js' */ 'E:/workerspace/fDeerGod/src/pages/Site/Org/models/orgInfo.js').then(
                          m => {
                            return { namespace: 'orgInfo', ...m.default };
                          },
                        ),
                        import(/* webpackChunkName: 'p__Site__models__widthdrawmodel.js' */ 'E:/workerspace/fDeerGod/src/pages/Site/models/widthdrawmodel.js').then(
                          m => {
                            return {
                              namespace: 'widthdrawmodel',
                              ...m.default,
                            };
                          },
                        ),
                      ],
                      component: () =>
                        import(/* webpackChunkName: "layouts__BasicLayout" */ '../Site/Org/Department'),
                      LoadingComponent: require('E:/workerspace/fDeerGod/src/components/PageLoading/index')
                        .default,
                    })
                  : require('../Site/Org/Department').default,
                exact: true,
              },
              {
                path: '/basic/org/paypwd',
                name: 'pwd',
                component: __IS_BROWSER
                  ? _dvaDynamic({
                      app: require('@tmp/dva').getApp(),
                      models: () => [
                        import(/* webpackChunkName: 'p__Site__Org__models__orgInfo.js' */ 'E:/workerspace/fDeerGod/src/pages/Site/Org/models/orgInfo.js').then(
                          m => {
                            return { namespace: 'orgInfo', ...m.default };
                          },
                        ),
                        import(/* webpackChunkName: 'p__Site__models__widthdrawmodel.js' */ 'E:/workerspace/fDeerGod/src/pages/Site/models/widthdrawmodel.js').then(
                          m => {
                            return {
                              namespace: 'widthdrawmodel',
                              ...m.default,
                            };
                          },
                        ),
                      ],
                      component: () =>
                        import(/* webpackChunkName: "layouts__BasicLayout" */ '../Site/Org/DealPwd'),
                      LoadingComponent: require('E:/workerspace/fDeerGod/src/components/PageLoading/index')
                        .default,
                    })
                  : require('../Site/Org/DealPwd').default,
                exact: true,
              },
            ],
          },
          {
            path: '/basic/role',
            name: 'role',
            icon: 'icon-icon_jiaosexinxiguanli',
            btns: [
              {
                key: 'edit',
                label: '修改',
              },
            ],
            component: __IS_BROWSER
              ? _dvaDynamic({
                  app: require('@tmp/dva').getApp(),
                  models: () => [
                    import(/* webpackChunkName: 'p__Site__Role__models__role.js' */ 'E:/workerspace/fDeerGod/src/pages/Site/Role/models/role.js').then(
                      m => {
                        return { namespace: 'role', ...m.default };
                      },
                    ),
                    import(/* webpackChunkName: 'p__Site__models__widthdrawmodel.js' */ 'E:/workerspace/fDeerGod/src/pages/Site/models/widthdrawmodel.js').then(
                      m => {
                        return { namespace: 'widthdrawmodel', ...m.default };
                      },
                    ),
                  ],
                  component: () =>
                    import(/* webpackChunkName: "layouts__BasicLayout" */ '../Site/Role/RoleList'),
                  LoadingComponent: require('E:/workerspace/fDeerGod/src/components/PageLoading/index')
                    .default,
                })
              : require('../Site/Role/RoleList').default,
            exact: true,
          },
          {
            path: '/basic/settings',
            name: 'settings',
            component: __IS_BROWSER
              ? _dvaDynamic({
                  app: require('@tmp/dva').getApp(),
                  models: () => [
                    import(/* webpackChunkName: 'p__Account__Settings__models__geographic.js' */ 'E:/workerspace/fDeerGod/src/pages/Account/Settings/models/geographic.js').then(
                      m => {
                        return { namespace: 'geographic', ...m.default };
                      },
                    ),
                  ],
                  component: () =>
                    import(/* webpackChunkName: "layouts__BasicLayout" */ '../Account/Settings/Info'),
                  LoadingComponent: require('E:/workerspace/fDeerGod/src/components/PageLoading/index')
                    .default,
                })
              : require('../Account/Settings/Info').default,
            icon: 'icon-gerenshezhi2',
            btns: [
              {
                key: 'edit',
                label: '修改',
              },
            ],
            hideChildrenInMenu: true,
            routes: [
              {
                path: '/basic/settings',
                redirect: '/basic/settings/personal',
                exact: true,
              },
              {
                path: '/basic/settings/personal',
                name: 'personal',
                component: __IS_BROWSER
                  ? _dvaDynamic({
                      app: require('@tmp/dva').getApp(),
                      models: () => [
                        import(/* webpackChunkName: 'p__Account__Settings__models__geographic.js' */ 'E:/workerspace/fDeerGod/src/pages/Account/Settings/models/geographic.js').then(
                          m => {
                            return { namespace: 'geographic', ...m.default };
                          },
                        ),
                      ],
                      component: () =>
                        import(/* webpackChunkName: "layouts__BasicLayout" */ '../Account/Settings/PersonalView'),
                      LoadingComponent: require('E:/workerspace/fDeerGod/src/components/PageLoading/index')
                        .default,
                    })
                  : require('../Account/Settings/PersonalView').default,
                btns: [
                  {
                    key: 'sendInvite',
                    label: '邀请',
                  },
                ],
                exact: true,
              },
              {
                path: '/basic/settings/invitedorg',
                name: 'invitedorg2',
                component: __IS_BROWSER
                  ? _dvaDynamic({
                      app: require('@tmp/dva').getApp(),
                      models: () => [
                        import(/* webpackChunkName: 'p__Site__Org__models__orgInfo.js' */ 'E:/workerspace/fDeerGod/src/pages/Site/Org/models/orgInfo.js').then(
                          m => {
                            return { namespace: 'orgInfo', ...m.default };
                          },
                        ),
                        import(/* webpackChunkName: 'p__Site__models__widthdrawmodel.js' */ 'E:/workerspace/fDeerGod/src/pages/Site/models/widthdrawmodel.js').then(
                          m => {
                            return {
                              namespace: 'widthdrawmodel',
                              ...m.default,
                            };
                          },
                        ),
                      ],
                      component: () =>
                        import(/* webpackChunkName: "layouts__BasicLayout" */ '../Site/Org/InvitedOrg'),
                      LoadingComponent: require('E:/workerspace/fDeerGod/src/components/PageLoading/index')
                        .default,
                    })
                  : require('../Site/Org/InvitedOrg').default,
                btns: [
                  {
                    key: 'view',
                    label: '查看',
                  },
                ],
                needAuth: true,
                exact: true,
              },
              {
                path: '/basic/settings/security',
                component: __IS_BROWSER
                  ? _dvaDynamic({
                      app: require('@tmp/dva').getApp(),
                      models: () => [
                        import(/* webpackChunkName: 'p__Account__Settings__models__geographic.js' */ 'E:/workerspace/fDeerGod/src/pages/Account/Settings/models/geographic.js').then(
                          m => {
                            return { namespace: 'geographic', ...m.default };
                          },
                        ),
                      ],
                      component: () =>
                        import(/* webpackChunkName: "layouts__BasicLayout" */ '../Account/Settings/SecurityView'),
                      LoadingComponent: require('E:/workerspace/fDeerGod/src/components/PageLoading/index')
                        .default,
                    })
                  : require('../Account/Settings/SecurityView').default,
                exact: true,
              },
            ],
          },
          {
            path: '/basic/journal',
            name: 'journal',
            icon: 'icon-dingdan3',
            component: __IS_BROWSER
              ? _dvaDynamic({
                  app: require('@tmp/dva').getApp(),
                  models: () => [
                    import(/* webpackChunkName: 'p__Boss__Journal__models__journal.js' */ 'E:/workerspace/fDeerGod/src/pages/Boss/Journal/models/journal.js').then(
                      m => {
                        return { namespace: 'journal', ...m.default };
                      },
                    ),
                  ],
                  component: () =>
                    import(/* webpackChunkName: "layouts__BasicLayout" */ '../Boss/Journal/JournalList'),
                  LoadingComponent: require('E:/workerspace/fDeerGod/src/components/PageLoading/index')
                    .default,
                })
              : require('../Boss/Journal/JournalList').default,
            exact: true,
          },
        ],
      },
      {
        component: __IS_BROWSER
          ? _dvaDynamic({
              component: () =>
                import(/* webpackChunkName: "p__404" */ '../404'),
              LoadingComponent: require('E:/workerspace/fDeerGod/src/components/PageLoading/index')
                .default,
            })
          : require('../404').default,
        needAuth: false,
        exact: true,
      },
    ],
  },
];
window.g_routes = routes;
const plugins = require('umi/_runtimePlugin');
plugins.applyForEach('patchRoutes', { initialValue: routes });

export { routes };

export default class RouterWrapper extends React.Component {
  unListen() {}

  constructor(props) {
    super(props);

    // route change handler
    function routeChangeHandler(location, action) {
      plugins.applyForEach('onRouteChange', {
        initialValue: {
          routes,
          location,
          action,
        },
      });
    }
    this.unListen = history.listen(routeChangeHandler);
    // dva 中 history.listen 会初始执行一次
    // 这里排除掉 dva 的场景，可以避免 onRouteChange 在启用 dva 后的初始加载时被多执行一次
    const isDva =
      history.listen
        .toString()
        .indexOf('callback(history.location, history.action)') > -1;
    if (!isDva) {
      routeChangeHandler(history.location);
    }
  }

  componentWillUnmount() {
    this.unListen();
  }

  render() {
    const props = this.props || {};
    return (
      <RendererWrapper0>
        <Router history={history}>{renderRoutes(routes, props)}</Router>
      </RendererWrapper0>
    );
  }
}
